@import "../../common/variables";

.grid-unit {
  position: relative;
  width: 18%;
  min-width: 360px;
  // min-width: 320px;
  
  overflow: hidden;

  transition: all 0.5s ease;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;

  // box-shadow: 2px 3px 9px 0px rgba(0, 0, 0, 0.174);

  
  &:hover {
    transform: scale(1.01);
    box-shadow: 2px 10px 20px 0px rgba(0, 0, 0, 0.174);
    .photo-details {
      opacity: 1;
      transform: translateY(0);
    }

    .artwork-teaser {
      .front-div {
        opacity: 1;
      }
      .back-div {
        .artwork-image {
          filter: grayscale(0%) sepia(0%) hue-rotate(0deg) saturate(100%);
        }
      }
    }
  }

  .artwork-teaser {
    width: 100%;
    height: 480px; // 420px;
    position: relative;

    .front-div,
    .back-div {
      width: 100%;
      height: 480px;
    }

    .front-div {
      pointer-events: none;
      opacity: 0;
      transition: opacity 0.5s ease;

      position: absolute;
      z-index: 2;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: flex-start;
      align-content: flex-start;
      
      .artwork-award {
        // height: 48px;
        margin: 8px;
        img {
          height: 42px;
          filter: drop-shadow(0px 2px 2px rgba(39, 39, 39, 0.5));
        }
      }
    }

    .back-div {
      cursor: pointer;
      position: absolute;
      z-index: 1;
      color: #4e4e4e;

      display: flex;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;

      .artwork-image {
        border-radius: 2px;
        width: 100%;
        height: 480px;
        // height: 560px;
        object-fit: cover;
        filter: grayscale(100%) sepia(0%) hue-rotate(0deg) saturate(100%);
        transition: filter 0.5s ease;
      }
    }
  }

  .photo-info {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 8px 0;
    font-family: $open-sans-regular, 'Arial', sans-serif;
    font-size: 0.9em;
    color: #666;

    .left {
      text-align: left; 
      font-size: 14px;
      .order {
        color: #9f9f9f;
      }
    }

    .right {
      text-align: right;

      .title {
        line-height: 1.5;
        font-weight: bold;
        font-size: 14px;
      }

      .year {
        line-height: 2;
        font-family: $open-sans-light, 'Arial', sans-serif;
      }
    }
  }
}

.my-modal .ant-modal-body {
  max-height: 80vh;
  overflow-y: auto;

  // background-color: #1b1b1b; 
}
.my-modal {
  .ant-modal-content {  
    padding: 0 0;
  }

  .section {
    margin: 32px 64px;

    .artwork-title {
      width: 70%;
      height: calc(80vw / 16 * 9);

      font-size: 48px;
      font-family: $alibaba-puhuiti-medium, $open-sans-bold, 'Arial', sans-serif;
      color: rgba(255, 255, 255, 0.53);
      // pointer-events: none;

      display: flex;
      // flex-wrap: wrap;
      align-items: center;
      justify-content: center;

      transition: all 0.5s ease;
      &:hover {
        color: rgb(255, 255, 255);
      }
    }

    .artwork-story {
      // .quote {
      //   color: $blue-heavy; // $black-base;// $blue-heavy;
      //   font-family: $qianmo-handwrite;
      //   font-size: 16px;
      // }
      .quote {
        line-height: 1.8;
  
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
  
        font-family: $open-sans-light, 'Arial', sans-serif;
      }
    }

    .award {
      display: flex;
      flex-direction: row;

      .content {
        padding: 0px 8px;
        margin-right: 8px;
        background-color: $red-middle; // $blue-light;
        border-radius: 12px;
        padding: 0px 8px;
        font-size: 10px;
        font-family: $open-sans-regular, 'Arial', sans-serif;
      }
    }

    .gallery {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      video {
        margin-top: 2px;
        height: 168px;
        width: auto;
      }
      .ant-image {
        margin: 2px;
        // height: 48px !important;
        // width: auto !important;

        img {
          height: 168px;
          width: auto;
        }
      }
    }

    .media-unit {
      margin: 8px 0;

      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: flex-start;

      .media-unit-section {
        margin: 2px 0;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: flex-start;
      }
      .field-wrapper {
        width: 48px;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;

        .field {
          background-color: $red-middle; // $blue-light;
          border-radius: 12px;
          padding: 0px 8px;
          font-size: 10px;
          font-family: $open-sans-regular, 'Arial', sans-serif;
        }
      }

      .content {
        margin-left: 8px;
        font-size: 12px;
        font-family: $open-sans-regular, 'Arial', sans-serif;

        // background-color: #aeaeae;
        color: $black-base; 
        a {
          color: $black-base;  
          text-decoration: underline;
        }
      }

      .media-name {

      }
      .media-title {

      }
    }

    .story-image-wrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;

      .story-image-div {
        margin: 16px;
        height: 300px;
        object-fit: contain;

        .ant-image {
          height: 100%;
          img {
            height: 100%;
          }
        }

      }
    }

    .title {
      margin-top: 12px;
      font-size: 14px;
      color: $black-base;
      font-weight: bold;
      font-family: $open-sans-bold, $alibaba-puhuiti-bold, 'Open Sans', 'Gill Sans MT', 'Calibri', 'Trebuchet MS', sans-serif;
      line-height: 1.3em;
      letter-spacing: 1px;
    }
    .section-title {
      margin-top: 12px;
      font-size: 14px;
      color: $green-regular;
      font-weight: bold;
      font-family: $open-sans-bold, $alibaba-puhuiti-bold, 'Open Sans', 'Gill Sans MT', 'Calibri', 'Trebuchet MS', sans-serif;
      line-height: 1.3em;
      letter-spacing: 1px;
    }
    .author {
      margin-top: 6px;
      line-height: 1.4em;
      letter-spacing: 1px;
      .author-highlight {
        font-family: $open-sans-bold, $alibaba-puhuiti-bold, 'Open Sans', 'Gill Sans MT', 'Calibri', 'Trebuchet MS', sans-serif;
      }
    }
    .supp {
      margin-top: 6px;
      font-size: 12px;
      color: grey;
      font-family: $open-sans-light, $alibaba-puhuiti-light, 'Open Sans', 'Gill Sans MT', 'Calibri', 'Trebuchet MS', sans-serif;
      a {
        font-size: 12px;
        color: grey;
        font-family: $alibaba-puhuiti-light;
      }
      line-height: 1.5;
  
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
  
      .supp-unit-div {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: center;
  
        margin-left: 2px;
  
        img {
          width: auto;
          height: 14px;
          margin-left: 4px;
        }
        a {
          margin-left: 4px;
        }
      }
    }
  }
 }