* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

.life-div-homepage {
	width: 100%;
}

// body {
// 	font-family: 'Inter', -apple-system, sans-serif;
// 	background: #f5f5f5;
// 	height: 100vh;
// 	display: flex;
// 	align-items: center;
// 	padding: 1rem;
// }

.portfolio {
	width: 100%;
	// max-width: 1600px;
	margin: 0 auto;
}

.portfolio__container {
	width: 100%;

	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-evenly;
	align-items: center;

	// gap: 0.5rem;
	// height: calc(100vh - 2rem);
	max-height: 500px;

	a {
		width: 23%;
		height: 100%;
		max-height: 500px;
		.portfolio__card-wrapper {
			width: 100%;
			aspect-ratio: 3/4;
			height: 100%;
			max-height: 100%;
			cursor: pointer;
		}
	}


}




.card {
	position: relative;
	width: 100%;
	height: 100%;
	padding: 1.5rem;
	// transition: transform 0.6s ease;

	border-radius: 3px;
}

.card {
	transition: transform 0.6s ease-in-out;
}

.card:hover {
	transform: translateY(-8px);
}

.card__number {
	position: absolute;
	top: 1rem;
	right: 1rem;
	font-size: 10px;
	opacity: 0.5;
	font-weight: 400;
}

.card__header {
	margin-bottom: 1rem;
}

.card__header h2,
.card__header h3 {
	font-size: 10px;
	letter-spacing: 0.2em;
	text-transform: uppercase;
}

.card__header h2 {
	font-weight: 500;
}

.card__header h3 {
	font-weight: 400;
}

/* Card Variants */
.card--kensho {
	background-color: #efd9ce;
	transition: background-color 0.6s ease-in-out;

	// add a hover effect
	&:hover {
		background-color: #efd9cec1;
	}
}

.kensho-text {
	position: absolute;
	bottom: 1.5rem;
	left: 1.5rem;
	display: flex;
	flex-direction: column;
	font-family: 'Times New Roman', serif;
	color: #7c2d12;
	font-size: 1rem;
	line-height: 1.2;

	pointer-events: none;
}

.card--reified {
	background-color: #dec0f1;
	// backgroundImage: set transition
	transition: background-color 0.6s ease-in-out;

	// add a hover effect
	&:hover {
		background-color: #dec0f1c1;
	}
}

.reified-text {
	margin-top: 2rem;
	pointer-events: none;
}

.reified-text .life {
	font-size: 1.5rem;
	font-weight: 300;
	color: #374151;
	margin-bottom: 0.25rem;
}

.reified-text .rei,
.reified-text .fied {
	font-size: 1.5rem;
	font-weight: 700;
	color: #374151;
	margin-bottom: 0.25rem;
}

.card--zine {
	background-color: #b79ced;
	transition: background-color 0.6s ease-in-out;

	// add a hover effect
	&:hover {
		background-color: #b79cedc1;
	}
}

.zine-text {
	margin-top: 2rem;
	font-size: 1.5rem;
	font-weight: 300;
	line-height: 1.2;
	pointer-events: none;
}

.zine-text > div {
	margin-bottom: 0.25rem;
}

.card--concept {
	background-color: #000;
	color: #fff;
}

.shapes {
	margin-top: 2rem;
}

.shapes-text {
	font-size: 0.875rem;
	margin-bottom: 1rem;
}

.shapes-icons {
	display: flex;
	align-items: center;
	gap: 1rem;
	font-size: 1.5rem;
}

.card--cad {
	background-color: #957fef;
	color: #fff;
	transition: background-color 0.6s ease-in-out;

	// add a hover effect
	&:hover {
		background-color: #957fefc1;
	}
}

.portfolio-text {
	position: absolute;
	bottom: 1.5rem;
	right: 1.5rem;
	text-align: right;
	font-size: 10px;
	letter-spacing: 0.1em;
	pointer-events: none;
}

@media (max-width: 768px) {
	.portfolio__container {
		flex-direction: column;
		max-height: none;
		// gap: 1rem;

		a {
			width: 95%;
			margin: 16px;
			.portfolio__card-wrapper {
				width: 100%;
				aspect-ratio: 16/7;
			}
		}	
	}

	.portfolio__card-wrapper {
		width: 100%;
	}

	.kensho-text {
		font-size: 0.6rem;
	}
	.reified-text .life, 
	.reified-text .rei,
	.reified-text .fied {
		font-size: 1rem;
	}
	.zine-text {
		font-size: 1rem;
	}
}