@import '../../common/variables.scss';


@media only screen and (min-width: 1921px) {
	.container {
		width: 1560px;
		padding-left: 80px;
		padding-right: 80px;
	}
}

.container {

    p {
		font-size: $plain-text-font-size;
	}
	
	background-color: #000;
	color: #fff;
	margin-top: 36px;
	// padding-left: 8vw;
    // padding-right: 8vw;

	font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
	letter-spacing: 0.03em;

	.header {
		display: flex;
		justify-content: space-between;
		margin-bottom: 24px;
	}
	  
	.logo {
		font-size: 120px;
		margin-bottom: 40px;

		@media (max-width: 475px) {
			font-size: 60px;
			margin-bottom: 20px;
		}
	}
	  
	.sectionTitle {
		color: #9E62FFff;
		margin-top: 108px;
		margin-bottom: 24px;
		font-size: 24px;
		font-family: $open-sans-bold, $alibaba-puhuiti-bold;
		letter-spacing: 0.06em;

		@media (max-width: 475px) {
			margin-top: 44px;
			font-size: 20px;
		}
	}

	.introduction {
		font-size: 20px;
		line-height: 1.3;

		display: flex;
		justify-content: space-between;

		@media (min-width: 475px) {
			// width: 50%;

			display: flex;
			justify-content: space-between;

			// .introduction-text {
			// 	width: 70%;
			// }
	
			// .introduction-image {
			// 	width: 30%;
			// 	img {
			// 		width: 100%;
			// 	}
			// }
		}

		@media (max-width: 475px) {
			// display: flex;
			// flex-direction: column;

			// .introduction-text {
			// 	width: 100%;
			// }
	
			// .introduction-image {
			// 	width: 100%;
			// 	margin-top: 16px;
			// 	img {
			// 		width: 100%;
			// 	}
			// }
		}

		
	}
	.featuredNews {
		// display: grid;
		// grid-template-columns: 1fr 1fr;
		// gap: 16px;
	 
		// @media (max-width: 768px) {
		// 	grid-template-columns: 1fr;
		// }
	}


	.newsGrid {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 16px;

		@media (max-width: 475px) {
			grid-template-columns: 1fr;
		}
	}
	  
	.newsCard {
		background-color: #1b1b1b;
		padding: 16px;
	  
		&Large {
			grid-column: span 2;

			@media (max-width: 475px) {
				grid-column: span 1;
			}
		}
	}
	  
	.category {
		color: #888;
	}
	  
	.title {
		margin-top: 8px;
		line-height: 1.3;
		font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
		letter-spacing: 0.1em;

		@media (max-width: 475px) {
			font-size: 14px;
		}
	}
	  
	.excerpt {
		font-size: 14px;
		margin-top: 8px;
		line-height: 1.3;

		@media (max-width: 475px) {
			font-size: 12px;
		}
	}
	  
	.readMore {
		display: flex;
		align-items: center;
		color: #fff;
		margin-top: 8px;
		background: none;
		border: none;
		cursor: pointer;
		padding: 0;

		@media (max-width: 475px) {
			font-size: 12px;
		}
	}
	  
	.aboutUs {
		margin-top: 40px;
		font-size: $plain-text-font-size;

		@media (max-width: 475px) {
			margin-top: 20px;
		}
	}
	  
	.number {
		font-size: 120px;
		margin: 0;

		@media (max-width: 475px) {
			font-size: 60px;
		}
	}
	  
	.discoverMore {
		display: flex;
		align-items: center;
		color: #fff;
		margin-top: 8px;
		background: none;
		border: none;
		cursor: pointer;
		padding: 0;

		@media (max-width: 475px) {
			font-size: 12px;
		}
	}
}


.second-section-div {
	display: flex;
	flex-direction: row; 

	.featured-news-div {
		width: 60%;
	}
	.roles-div {
		width: 40%;
	}

	.featured-news-div {
		margin-bottom: 40px;
	}

	.roles-div {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;

		margin-left: 3%;

		.roles-image {
			width: 100%;
			align-self: center;
		}
	}

	@media (max-width: 767px) {
		flex-direction: column;

		.roles-div  {
			margin-left: 0%;
		}

		.featured-news-div {
			width: 100%;
		}
		.roles-div {
			width: 100%;
		}
	}
}