@import '../../../common/variables.scss';

@media only screen and (max-width: 768px) {
	
	.grid {
		grid-template-columns: 1fr; // Stack columns on smaller screens
		grid-template-rows: auto; // Adjust rows to fit content
	}

	.teaser {
		grid-column: span 1; // Full width on mobile
		grid-row: auto;
		overflow: hidden;
		border: 1px solid #333;
		padding: 0.5rem;

		img {
			width: 100%;
			height: auto; // Maintain aspect ratio
			object-fit: cover;
		}
	}

	.left-parent, .right-parent {
		grid-column: span 1; // Full width on mobile
		grid-row: auto;
	}

	.artwork-card-div .unified .grid .left, 
	.artwork-card-div .unified .grid .right {
		grid-column: span 1; // Full width on mobile
		width: 100%; // Full width for both sections
		padding: 0.25rem; // Adjust padding for smaller screens
	}

	.main-read-more {
		grid-column: span 1; // Full width on mobile
	}
}

@media only screen and (min-width: 768px) {
	.grid {
		grid-template-columns: repeat(10, 1fr); // Define 10 equal columns
		grid-template-rows: repeat(3, 1fr);
		aspect-ratio: 16 / 9;
	}
	.teaser {
		grid-column: 1 / 5; // Teaser takes 4/10 of the width
		grid-row: span 3;
	}
	.left-parent {
		grid-column: 5 / 7; // Left-parent takes 3/10 of the width
		grid-row: span 3;
		// width: 70%; // Restrict the width
	}
	.right-parent {
		grid-column: 7 / 11; // Right-parent takes 3/10 of the width
		grid-row: span 3;
		// width: 100%; // Restrict the width
		overflow: hidden;
	}

	.artwork-card-div .unified .grid .left,
	.artwork-card-div .unified .grid .right {
		width: 100%; // Ensure full width within their grid area
		padding: 0.5rem;
	}
}

.artwork-card-div {
	display: grid;
	grid-gap: 0;

	background-color: #000;
	color: #fff;

	/* Hide scrollbar for Chrome, Safari and Opera */
	::-webkit-scrollbar {
		display: none;
	}

	/* Hide scrollbar for IE, Edge and Firefox */
	-ms-overflow-style: none;  /* IE and Edge */
	scrollbar-width: none;  /* Firefox */
	
	// gap: 1px;
	// outline: 0.5px solid #333;
	// border: 0.5px solid #333;
	
  
	// > * {
	// //   border: 1px solid #333;
	// outline: 0.5px solid #333;
	// }

	margin-bottom: 64px;
	
	.exhibition-description {
		// height: 100%;
		overflow-y: auto;
		line-height: 1.5;
		letter-spacing: 0.05rem;
		color: #dcdcdc;
	}
  
	.teaser {
	//   grid-column: span 4;
	//   grid-row: span 3;
	  overflow: hidden;
	  border: 1px solid #333;
	  padding: 0.5rem;
  
	  img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	  }
	}

	.read-more {
		align-self: flex-end;
		background-color: transparent;
		font-size: 0.85rem;
		padding: 0;
		color: #aaa;
		border: none;
		cursor: pointer;
	}

	.unified {
		.grid {			
			display: flex;
			background-color: #1019278b;	
			border: 1px solid #333;
			
			.left {
			  img {
				  width: 100%;
				  height: 100%;
				  object-fit: cover;
			  }
			}
	  
			.right {
			  .content {
				  width: 100%;
				  display: flex;
				  flex-direction: column;

				  .header {
					  display: flex;
					  justify-content: space-between;
					  font-size: 0.85rem;
					  color: #888;
				  }
				  .title {
					color: #dcdcdc;
					font-family: $open-sans-bold, $alibaba-puhuiti-bold, 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
				  }
				  .background-idea {
					.description {
						color: #dcdcdc;
					}
				  }

				  .award-logo-list {
					display: flex;
					flex-direction: row;
					flex-wrap: wrap;
					justify-content: space-evenly;
					align-content: space-between;
					height: 100%;
		
					img {
						max-width: 40%;
						max-height: 40%;
						width: auto;
						height: auto;
						object-fit: contain;
						margin: 5%;
					}
				}
			  }
	  
			  .title {
				  font-size: 0.9rem;
				  font-weight: bold;
				  margin: 0.25rem 0;
				}
			
				p {
				//   font-size: 0.8rem;
				  font-size: $plain-text-font-size;
				  color: #aaa;
				}
			
				.read-more {
				  align-self: flex-end;
				  background-color: transparent;
				  font-size: 0.85rem;
				  padding: 0;
				  color: #fff;
				  border: none;
				  cursor: pointer;
				}
			}
	  
		 }
	}

	.left-parent {
		// grid-column: 5 / 8;
		// grid-row: span 3;

		.secondary-image {
			height: calc(100% / 3);

			.right {
				.content {
					height: 100%;
					.background-idea {
						height: calc(100% - 3rem);
						margin-top: 1rem;
						overflow-y: auto;
					}
				}

			}
		}

		.empty-space {
			height: calc(100% / 3);
			// grid-column: 3;
			// grid-row: 1;
			
			position: relative; // 确保父元素是相对定位
	  
			display: flex;
			flex-direction: row;
	  
			.empty {
			  padding: 0;
			}
	  
			.half {
			  width: 100%;
			  height: 100%; // Make this div 100% height
			  position: relative;
			  border-right: 0.5px solid #333;
			}
	  
			.diagonal-line {
			  width: 100%;
			  height: 100%; // This div is already 100% height
			  background: linear-gradient(to bottom right, transparent 49.75%, #333 49.75%, #333 50.25%, transparent 50.25%);
			  z-index: 1; // 确保在其他元素之上
			}
		}
		
		.award {
		height: calc(100% / 3);
		padding: 0.5rem;
		// grid-column: 3;
		// grid-row: 2 / 3;
		}
	}

	.right-parent {
		// grid-column: 8 / 11;
		// grid-row: span 3;

		.exihibition {
			width: 100%;

			display: flex;
			flex-direction: column;
			padding: 0.5rem;

			height: 100%;
		
			img {
			  width: 100%;
			  height: 33%;
			  object-fit: cover;
			}
		
			.content {
			  margin-top: 1rem;
			  height: 67%;
			  width: 100%;

			  .header {
				display: flex;
				justify-content: space-between;
				font-size: 0.85rem;
				color: #888;
			  }

			  .exihibition-content {
				height: calc(100% - 4rem);
				margin-top: 1rem;
				overflow-y: auto;

				.title {
					font-family: $open-sans-bold, $alibaba-puhuiti-bold, 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
					font-weight: bold;
					color: #dcdcdc;
				}
			  }

			  .scroll-hint {
				margin-top: 8px;
				// height: 3rem;
				display: flex;
				justify-content: flex-end;
				align-items: center;
			  }

			}

			.image-container {
				position: relative;
				width: 100%;
				padding-top: 56.25%; // 16:9 aspect ratio
				overflow: hidden;

				img {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
				
			}
		}
	}
  }

 
.artwork-modal {
	max-height: 80vh;
	overflow-y: auto;

	.ant-modal-content {
		background-color: #232323;

		color: #aeaeae;
		.section-title {
			color: #aeaeae;
		}
		.title {
			color: #aeaeae;
		}
		.award .content {
			background-color: #aeaeae;
			color: #000;
		}
	}

	.ant-tabs {
		.ant-tabs-tab {
			color: #aeaeae;
		}
		.ant-tabs-tab-btn {
			color: #aeaeae;
		}
	
		.ant-tabs-tab-active {
			.ant-tabs-tab-btn {
				color: #aeaeae;
			}
		}
		.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
			color: #9e62ff;
		}
		.ant-tabs-ink-bar {
			background-color: #9e62ff;
		}
		.ant-tabs-tabpane {
			color: #aeaeae;
		}
		.ant-tabs-tabpane-active {
			color: #aeaeae;
		}
	}
	
}

.artwork-modal {
	.ant-modal-content {  
	  padding: 0 0;
	}
  
	.section {
	  margin: 32px 64px;
  
	  .artwork-title {
		width: 70%;
		height: calc(80vw / 16 * 9);
  
		font-size: 48px;
		font-family: $alibaba-puhuiti-medium, $open-sans-bold, 'Arial', sans-serif;
		color: rgba(255, 255, 255, 0.53);
		// pointer-events: none;
  
		display: flex;
		// flex-wrap: wrap;
		align-items: center;
		justify-content: center;
  
		transition: all 0.5s ease;
		&:hover {
		  color: rgb(255, 255, 255);
		}
	  }
  
	  .artwork-story {
		// .quote {
		//   color: $blue-heavy; // $black-base;// $blue-heavy;
		//   font-family: $qianmo-handwrite;
		//   font-size: 16px;
		// }
		.quote {
		  line-height: 1.8;
	
		  display: flex;
		  justify-content: flex-start;
		  align-items: flex-start;
	
		  font-family: $open-sans-light, 'Arial', sans-serif;
		}
	  }
  
	  .award {
		display: flex;
		flex-direction: row;
  
		.content {
		  padding: 0px 8px;
		  margin-right: 8px;
		  background-color: #aeaeae;
		  border-radius: 12px;
		  padding: 0px 8px;
		  font-size: 10px;
		  font-family: $open-sans-regular, 'Arial', sans-serif;
		}
	  }
  
	  .gallery {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
  
		video {
		  margin-top: 2px;
		  height: 168px;
		  width: auto;
		}
		.ant-image {
		  margin: 2px;
		  // height: 48px !important;
		  // width: auto !important;
  
		  img {
			height: 168px;
			width: auto;
		  }
		}
	  }
  
	  .media-unit {
		margin: 8px 0;
  
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		justify-content: flex-start;
		align-items: flex-start;
  
		.media-unit-section {
		  margin: 2px 0;
		  display: flex;
		  flex-direction: row;
		  flex-wrap: nowrap;
		  justify-content: flex-start;
		  align-items: flex-start;
		}
		.field-wrapper {
		  width: 48px;
		  display: flex;
		  flex-direction: row;
		  flex-wrap: nowrap;
		  justify-content: flex-start;
  
		  .field {
			background-color: #aeaeae;
			color: #000; 
			border-radius: 12px;
			padding: 0px 8px;
			font-size: 10px;
			font-family: $open-sans-regular, 'Arial', sans-serif;
		  }
		}
  
		.content {
		  margin-left: 8px;
		  font-size: 12px;
		  font-family: $open-sans-regular, 'Arial', sans-serif;
  
		  background-color: #aeaeae;
		  color: #000; 
		  a {
			color: #000; 
			text-decoration: underline;
		  }
		}
  
		.media-name {
  
		}
		.media-title {
  
		}
	  }
  
	  .story-image-wrapper {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
  
		.story-image-div {
		  margin: 16px;
		  height: 300px;
		  object-fit: contain;
  
		  .ant-image {
			height: 100%;
			img {
			  height: 100%;
			}
		  }
  
		}
	  }
  
	  .title {
		margin-top: 12px;
		font-size: 14px;
		color: $black-base;
		font-weight: bold;
		font-family: $open-sans-bold, $alibaba-puhuiti-bold, 'Open Sans', 'Gill Sans MT', 'Calibri', 'Trebuchet MS', sans-serif;
		line-height: 1.3em;
		letter-spacing: 1px;
	  }
	  .section-title {
		margin-top: 12px;
		font-size: 14px;
		color: $green-regular;
		font-weight: bold;
		font-family: $open-sans-bold, $alibaba-puhuiti-bold, 'Open Sans', 'Gill Sans MT', 'Calibri', 'Trebuchet MS', sans-serif;
		line-height: 1.3em;
		letter-spacing: 1px;
	  }
	  .author {
		margin-top: 6px;
		line-height: 1.4em;
		letter-spacing: 1px;
		.author-highlight {
		  font-family: $open-sans-bold, $alibaba-puhuiti-bold, 'Open Sans', 'Gill Sans MT', 'Calibri', 'Trebuchet MS', sans-serif;
		}
	  }
	  .supp {
		margin-top: 6px;
		font-size: 12px;
		color: grey;
		font-family: $open-sans-light, $alibaba-puhuiti-light, 'Open Sans', 'Gill Sans MT', 'Calibri', 'Trebuchet MS', sans-serif;
		a {
		  font-size: 12px;
		  color: grey;
		  font-family: $alibaba-puhuiti-light;
		}
		line-height: 1.5;
	
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: flex-start;
	
		.supp-unit-div {
		  display: flex;
		  flex-direction: row;
		  flex-wrap: nowrap;
		  justify-content: flex-start;
		  align-items: center;
	
		  margin-left: 2px;
	
		  img {
			width: auto;
			height: 14px;
			margin-left: 4px;
		  }
		  a {
			margin-left: 4px;
		  }
		}
	  }
	}
   }




.fade-in {
	opacity: 0;
	transition: opacity 1s ease-in-out;
}

.fade-in.visible {
	opacity: 1;
}

