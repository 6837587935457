// add by yifang
@import "./common/variables";

// width: [1920px, +INF)
@media only screen and (min-width: 1921px) {
  // .content-div {
  //   width: 1920px;
  //   padding-left: 36px; 
  //   padding-right: 36px; 
  //   margin: 0 auto; // Center the container horizontally
  // }

  .research-page, .data-art-page {
    .content-div {
      width: 1560px;
      padding-left: 80px; 
      padding-right: 80px; 
      margin: 0 auto; // Center the container horizontally
    }
  }
  .data-art-page {
    .content-div {
      width: 100%;
      padding-left: 0;
      padding-right: 0;
    }
  }
} 
// width: [0, 1920px]
@media only screen and (max-width: 1920px) {
  .research-page, .data-art-page {
    width: 100vw;  
    .content-div {
      width: 100%;  
      padding-left: 8vw;
      padding-right: 8vw;

      .image-div {
        max-width: 300px;
      }
    }
  }
}

// width: [0px, 990px] (mobile devices) 
@media only screen and (max-width: 990px) {
  .right-div-collapse {
    background-color:rgba(255, 255, 255, 0.152)
  }
  #right-div {
      display: none;
  }
  .ant-layout-header {
    background-color: #fff;
    height: 80px;
    width: 100%;
  }
  .profile-image-div {
    margin-top: 0;
    padding: 16px;
  }
  .paper-unit-div {
    flex-direction: column;
    flex-wrap: nowrap;

    .paper-teaser {
      width: 90%;
    }
    .text-info {
      width: 90%;
    }
  }
  .data-art-parent-div {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
  }
  .experience-unit-div {
    margin-bottom: 24px;
    // border-bottom: 1px solid grey;
    
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap; 
    .text-info-service, .text-info {
      width: 100%;
      padding: 0 12px;
    }
    .logo {
      width: 50%;
      padding: 8px;
    }
    .rd-logo {
      width: 100%;
      padding: 8px;
    }
  }
}
// width: [990px, +INF) (desktop devices) 
@media only screen and (min-width: 991px) {
  #right-div-collapse {
      display: none;
  }
  .profile-image-div {
    margin-top: 72px;
    // padding: 16px;
    padding: 16px 0 16px 16px;
  }
  .paper-unit-div {
    flex-direction: row;
    flex-wrap: nowrap;
    .paper-teaser {
      width: 25%;
    }
    .text-info {
      width: 75%;
    }
  }
  .data-art-parent-div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
  }
  .experience-unit-div {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    .text-info-service, .text-info {
      width: 70%;
      padding: 16px;
    }
    .logo, .rd-logo {
      width: 20%;
      padding: 16px;
    }
  }
}


header {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-layout-header {
  // box-shadow: 3px 3px 8px rgba(52, 52, 52, .2);
  padding: 24px;
  position: fixed;
  top: 0;
  transition: all .5s;
  width: 100%;
  z-index: 100;

  flex: 0 0 auto;
  
}

.ant-layout {
  // background-color: #f0f2f5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-self: center;
  // min-height: 100vh;
}

.header-div {
  width: 100%;
  // width: 84%;
  height: auto;

  // height: 64px;
  background-color: rgba(255, 255, 255, 0.8);
  position: fixed;
  z-index: 100;

  transition: height 0.5s ease;

  .ant-row  {
    height: 100%;

    .left-div {
      // width: 30%;
      height: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      // justify-content: space-between;
      align-items: center;

      .logo {
        height: 100%;

        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;

        img {
          width: auto;
          // height: 50%;
          height: 22px;
        }
      }
      .text {
        margin-left: 12px;
        font-size: 24px;
        letter-spacing: 1px;
        font-family: $open-sans-extrabold, $alibaba-puhuiti-bold, 'Open Sans', 'Gill Sans MT', 'Calibri', 'Trebuchet MS', sans-serif;
        // color: #3d3d3d;
      }
    }
    .right-div {
      display: flex;
      justify-content: flex-end;
      // width: 70%;
      // height: 100%;

      .right-div-inside {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;

        .ant-anchor-ink {
          font-size: $header-font-size;
          // font-weight: lighter;
          font-family: $open-sans-light, $alibaba-puhuiti-light, 'Open Sans', 'Gill Sans MT', 'Calibri', 'Trebuchet MS', sans-serif;

          background-color: $green-regular;
        }
        .ant-anchor-link {

          letter-spacing: 1px;

          .ant-anchor-link-title {

            color: $green-regular;

            font-family: $open-sans-regular, $alibaba-puhuiti-regular, 'Open Sans', 'Gill Sans MT', 'Calibri', 'Trebuchet MS', sans-serif;

            a {
              color: $green-regular;
              // color: #9E62FFff;
            }
          }
        }
      }
    }
    .right-div-collapse {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      // background-color: white;
      // z-index: 102;
      // position: fixed;
      // right: 10%;

      overflow: hidden;
      transition: opacity 0.7s ease, height 0.7s ease;

      &.hidden {
        opacity: 0;
        height: 0;
        padding: 0; // Remove padding when hidden to avoid extra space
      }

      &.visible {
        opacity: 1;
        height: auto; // Allows the height to adjust based on content
      }
    

      .menu {
        width: 52px;
        height: 42px;

        display: flex;
        justify-content: center;
        align-items: center;

        font-size: 20px;

        &:hover {
          cursor: pointer;
          transition: all 0.5s;
        }
      }
      .collapse-menu-div {
        width: calc(100% - 32px);
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;

        .collapse-menu-div-unit {
          // height: 30px;
          margin: 8px 0;

          a {
            letter-spacing: 1px;
            color: $green-regular;
              font-family: $open-sans-light, $alibaba-puhuiti-light, 'Open Sans', 'Gill Sans MT', 'Calibri', 'Trebuchet MS', sans-serif;
          }
        }
      }
      .hidden-md-up {
        margin-top: 1rem;
        margin-bottom: 1rem;
        border: 0;
        border-top: 2px solid rgba(0, 0, 0, 0.263);
      }
    }
  }
}

.content-div {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center; 
  align-items: center;

  padding-top: 108px;

  .page-div {
    display: flex;
    flex-direction: row;
    // flex-wrap: nowrap;
    justify-content: space-between;
  }

  .plain-text-div {
    p {
      margin-top: 0;
      margin-bottom: 16px;
      line-height: 160%; 
      // line-height: 1.65em; 

      .css-framework-step {
        font-family: $open-sans-bold, $alibaba-puhuiti-bold;;
      }
      .highlight-life {
        font-size: 0.8em;
        color: $blue-heavy;
        border-bottom: 1px dotted $blue-heavy;
      }
    }
    .highlight-sentence {
      margin-top: 0;
      margin-bottom: 16px;
      line-height: 1.6em;  

      // padding: 0 0 0 8px;
      padding: 8px;

      border-left: 3px solid $blue-heavy; // 

      color: $black-base; // '#bd6354'; 
      font-family: $alibaba-puhuiti-regular, $open-sans-regular, 'Open Sans', 'Gill Sans MT', 'Calibri', 'Trebuchet MS', sans-serif;

      background-color: rgba($green-light, 0.5); // Add opacity to $green-light

      a {
        font-family: $open-sans-bold, $alibaba-puhuiti-light, 'Open Sans', 'Gill Sans MT', 'Calibri', 'Trebuchet MS', sans-serif;
      }

      p {
        margin-bottom: 0px;
      }
    }
  }

  .profile-image-div {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;

    .image-div {
      img {
        width: 100%;
        height: 100%;
        // object-fit: cover;
      }
    }
    .short-bio-div {
      margin-top: 8px;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;

      a {
        font-size: 12px;
        color: $green-regular;
        font-family: $open-sans-regular, $alibaba-puhuiti-regular, 'Gill Sans MT', 'Calibri', 'Trebuchet MS', sans-serif;
        // text-decoration: underline;
      }

      .contact-logo-div {
        width: 32px;
        margin: 0 8px;

        img {
          width: 100%;
        }
      }
    }
    .identify-div {
      margin-top: 54px;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;

      .identity-row {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;

        .identity-unit {
          margin: 8px;
          padding: 16px;
          height: 32px;
          display: flex;
          justify-content: center;
          align-items: center;
  
          font-size: 11px;
          font-family: $open-sans-bold, $alibaba-puhuiti-bold, 'Open Sans', 'Gill Sans MT', 'Calibri', 'Trebuchet MS', sans-serif;
        }
        .cs {
          background-image: linear-gradient(179deg, rgba(129,84,175,0.56) 0%, rgba(129,84,175,0.00) 100%, rgba(129,84,175,0.10) 100%);
          border-radius: 24px;  
          color: #8154AF;
        }
        .css {
          background-image: linear-gradient(180deg, rgba(160,138,228,0.56) 0%, rgba(129,84,175,0.00) 100%);
          border-radius: 24px;
          color: #A08AE4;
        }
        .da {
          background-image: linear-gradient(180deg, rgba(188,141,212,0.56) 0%, rgba(188,141,212,0.01) 100%, rgba(188,141,212,0.00) 100%);
          border-radius: 24px;
          color: #BC8DD4;
        }
      }


    }

  }

  .paper-unit-div {
    display: flex;
    margin-bottom: 16px;

    .paper-teaser {
      padding: 12px;
      .img-div {
        padding: 4px;
        border: 1.5px solid $green-light;

        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
    .text-info {
      padding: 8px;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;

      

      .title {
        font-size: $plain-text-font-size; // 14px;
        color: $green-regular;
        font-family: $open-sans-bold, $alibaba-puhuiti-bold, 'Open Sans', 'Gill Sans MT', 'Calibri', 'Trebuchet MS', sans-serif;
        line-height: 160%;
      }
      .author {
        line-height: 160%;
        margin-top: 4px;
        margin-bottom: 4px;
        .author-highlight {
          font-family: $open-sans-bold, $alibaba-puhuiti-medium, 'Open Sans', 'Gill Sans MT', 'Calibri', 'Trebuchet MS', sans-serif;
        }
        font-family: $open-sans-light, $alibaba-puhuiti-light, 'Open Sans', 'Gill Sans MT', 'Calibri', 'Trebuchet MS', sans-serif;
      }
      .venue {
        line-height: 160%;
        margin-bottom: 4px;
        font-size: 14px;
        color: grey;
        font-family: $open-sans-light-italic, $alibaba-puhuiti-light, 'Open Sans', 'Gill Sans MT', 'Calibri', 'Trebuchet MS', sans-serif;

        .venue-short {
          font-family: $open-sans-bold, $alibaba-puhuiti-medium, 'Open Sans', 'Gill Sans MT', 'Calibri', 'Trebuchet MS', sans-serif;
        }
      }
      .paper-award-div {
        margin: 8px 0;
        
        .wrapper {
          width: 240px;
          border: solid $blue-light; 
          border-radius: 20px;
          padding: 1px 8px;
          background-color: $blue-light;

          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;

          .icon {
            display: flex;
            justify-content: center;
            align-items: center; 
            img {
              width: 14px;
              // height: 8px;
            }
          }
          .text {
            margin-left: 6px;
            display: flex;
            justify-content: center;
            align-items: center; 
            font-size: 11px;
            color: $blue-heavy;
            // color: rgb(49, 49, 49);
            font-family: $open-sans-regular, $alibaba-puhuiti-medium, 'Open Sans', 'Gill Sans MT', 'Calibri', 'Trebuchet MS', sans-serif;
          }


        }
      }
      .supp {
        line-height: 2;
        font-size: 12px;
        color: grey;
        font-family: $open-sans-light, $alibaba-puhuiti-light, 'Open Sans', 'Gill Sans MT', 'Calibri', 'Trebuchet MS', sans-serif;
        a {
          font-size: 12px;
          color: grey;
          font-family: $alibaba-puhuiti-light;
        }

        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;

        .supp-unit-div {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: flex-start;
          align-items: center;

          margin-left: 2px;

          img {
            width: auto;
            height: 14px;
            margin-left: 4px;
          }
          a {
            margin-left: 4px;
          }
        }
      }
    }
  }

  .news-div {
    width: 100%;

    .news-unit-div {
      width: 100%;
      margin-bottom: 12px;

      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: flex-start;

      .news-teaser {
        width: 100%;
        padding-top: 4px;

        display: flex;
        justify-content: flex-start;
        // img {
        //   width: 60%;
        //   margin: 4px 8px 4px 0px;
        // }
        .img-div {
          width: 60%;
          margin: 4px 8px 4px 0px;
          // height: 100%;
          position: relative;
          .ant-image {
            height: 100%;
            position: relative;
            overflow: hidden;
            // img {
            //   height: 100%;
            //   object-fit: cover;
            // }
          }
        }
      }
      .date {
        // width: 20%;
        margin-top: 4px;
        margin-bottom: 4px;
        color: $green-regular;
        margin-right: 16px;
        font-weight: bold;
        font-family: $open-sans-bold, $alibaba-puhuiti-bold, 'Open Sans', 'Gill Sans MT', 'Calibri', 'Trebuchet MS', sans-serif;
      }
      .content {
        // width: 80%;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: flex-start;

        line-height: 1.6;

        font-family: $open-sans-light, $alibaba-puhuiti-light, 'Open Sans', 'Gill Sans MT', 'Calibri', 'Trebuchet MS', sans-serif;

        a {
          // color: $red-dark;
          text-decoration: underline;
        }
      }
    }
  }

  .data-art-div {
    .queto {
      margin-top: 36px;
      margin-bottom: 24px; 
      line-height: 1.8;

      display: flex;
      justify-content: center;
      align-items: center;

      text-align: center;
    }
  }
  .data-art-parent-div {
    padding: 16px; 
    display: flex;
    flex-wrap: nowrap; /* Prevent wrapping */
    gap: 32px; /* Add some space between the images */
    padding: 32px 32px 36px 12px; /* Add padding to the container */

    /* Hide scrollbar */
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */


    overflow-x: auto;
    overflow-y: hidden; /* Initially hide vertical scroll */
  }

  .experience-unit-div {
    // display: flex;
    // flex-direction: row;
    // flex-wrap: nowrap;

    .text-info-service {
      // width: 70%;
      // padding: 16px;

      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;

      .role-type {
        color: $green-regular;
        font-family: $open-sans-bold, $alibaba-puhuiti-light, 'Open Sans', 'Gill Sans MT', 'Calibri', 'Trebuchet MS', sans-serif;
      }
    }

    .logo, .rd-logo {
      // width: 20%;
      // padding: 16px;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      // justify-content: center;
      align-items: center;

      .img-div {
        padding: 4px;
        border: 1.5px solid $green-light;

        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
    .text-info {
      // width: 80%;
      // padding: 16px;

      .date {
        font-family: $open-sans-bold, $alibaba-puhuiti-bold, 'Open Sans', 'Gill Sans MT', 'Calibri', 'Trebuchet MS', sans-serif;
        margin-top: 4px;
        margin-bottom: 4px;
      }
      .role {
        color: $green-regular;
        font-family: $open-sans-regular, $alibaba-puhuiti-regular, 'Open Sans', 'Gill Sans MT', 'Calibri', 'Trebuchet MS', sans-serif;
        margin-top: 4px;
        margin-bottom: 4px;
        

        span {
          font-family: $open-sans-bold, $alibaba-puhuiti-regular, 'Open Sans', 'Gill Sans MT', 'Calibri', 'Trebuchet MS', sans-serif;
          line-height: 1.5;
        }
      }
      .supervisor {
        font-family: $open-sans-light, $alibaba-puhuiti-light, 'Open Sans', 'Gill Sans MT', 'Calibri', 'Trebuchet MS', sans-serif;
        margin-top: 4px;
        margin-bottom: 4px;
      }
      .institution {
        font-family: $open-sans-light, $alibaba-puhuiti-light, 'Open Sans', 'Gill Sans MT', 'Calibri', 'Trebuchet MS', sans-serif;
        margin-top: 4px;
        margin-bottom: 4px;
        line-height: 1.5;
      }
      .location {
        font-family: $open-sans-light, $alibaba-puhuiti-light, 'Open Sans', 'Gill Sans MT', 'Calibri', 'Trebuchet MS', sans-serif;
        margin-top: 4px;
        margin-bottom: 4px;
      }
    }
  }

  .contact-div {
    padding: 16px;
  }

  .life-patent-div {
    // height: 480px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;

    .gallery {
      margin: 36px 16px;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: flex-start;

      .intro {
        // height: 20px;

        display: flex;
        justify-content: center;
        align-items: center;

        p {
          margin-top: 18px;
          line-height: 1.3;
        }
      }
      .photo-list {
        height: calc(50% - 40px);
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;

        .photo-unit-div {
          width: 7%;
          img {
            aspect-ratio: 1 / 1;
            object-fit: cover;
            width: 100%;
          }
          .ant-image-img {
            aspect-ratio: 1 / 1;
            object-fit: cover;
            width: 100%;
          }

          .ant-image {
            position: relative;
          }
          .ant-image:before {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: rgba(255, 255, 255, 0.152);// #426f6581; // #c5d6d048;
        }
        }
      }
    }
  }
}

.footer {
  margin-top: 108px;
  background-color: $blue-light; // $green-light;
  width: 100vw;
  height: 96px;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;

  .footer-div {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: center;

    font-family: $open-sans-light, $alibaba-puhuiti-light, 'Open Sans', 'Gill Sans MT', 'Calibri', 'Trebuchet MS', sans-serif;

    .left {
      width: 25%;
    }

    .center {
      width: 50%;
    }

    .right {
      width: 25%;
    }
  }

  @media only screen and (max-width: 600px) {
    flex-direction: column;
    text-align: center;

    height: 240px;

    .footer-div {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .left, .right {
        width: 100%;
        margin-bottom: 8px;
        line-height: 1.8;
      }
    }

    .left, .center, .right {
      width: 100%;
      margin-bottom: 8px;
      line-height: 1.8;
    }
  }
}

.ant-tooltip {
  --antd-arrow-background-color: $green-regular;// $green-regular;
}
.ant-tooltip-arrow {
  --antd-arrow-background-color: $green-regular;
}
.ant-tooltip-content {
  .ant-tooltip-inner {
    background-color: $red-middle; // $blue-light; // $green-light;
    // color: white;
    padding: 16px;
    font-size: 12px;
    letter-spacing: 1px;
    font-family: $open-sans-light, $alibaba-puhuiti-light, 'Open Sans', 'Gill Sans MT', 'Calibri', 'Trebuchet MS', sans-serif;
    color: #3d3d3d;
  }
}

.ant-tabs-tab-btn {
  color: $green-regular;
}

.ant-tabs-tab-active {
  .ant-tabs-tab-btn {
    color: $green-regular;
  }
}

.ant-tabs {
  font-size: $plain-text-font-size;
}

.ant-avatar {
  img{
    object-fit: contain;
  }
}

// .ant-image:before {
//   background: rgba(179, 50, 50, 0.5);
//   .ant-image-img {
//     // background: rgba(179, 50, 50, 0.5);
//   }
// }
// end of add by yifang


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



// // folded menu
// .header-right-div {
//   display: flex;
//   flex-direction: row;
//   flex-wrap: nowrap;
//   justify-content: flex-end;
//   align-items: center;

//   .ant-anchor-link {
//     .ant-anchor-link-title {
//       font-size: 14px;
//       font-family: $open-sans-bold;
//       color: $purple-40;;
//       // color: white;
//       letter-spacing: 2px;
//     }
//   }
//   .ant-anchor-link-active {
//     .ant-anchor-link-title-active {
//       color: $purple-80;
//     }
//   }
// }

// .header-right-div-collapse {
//   display: flex;
//   justify-content: flex-end;
//   .menu {
//     font-size: 28px;
//     color: $purple-60;
//     // color: white;
//   }
// }

// .collapse-menu-div.active {
//   opacity: 1;
//   right: 0;
// }

// .collapse-menu-div {
//   opacity: 0;
//   width: 240px;
//   background-color: $purple-80;

//   position: absolute;
//   top: 0;
//   right: -240px;
//   padding: 8px 0;
//   z-index: 99;
//   box-shadow: 3px 3px 8px rgba(52, 52, 52, 0.2);

//   display: flex;
//   flex-direction: column;
//   flex-wrap: nowrap;
//   justify-content: center;
//   align-items: center;

//   .close-div {
//     height: 32px;
//     width: 100%;
//     padding: 0 8px;
//     display: flex;
//     justify-content: flex-end;
//     align-items: center;

//     .menu {
//       font-size: 18px;
//       color: white;
//       cursor: pointer;

//       &:hover {
//         transform: scale(1.5);
//         color: black;
//         transition: all 0.5s;
//       }
//     }
//   }
//   .collapse-menu-div-unit {
//     padding: 8px;
//     a {
//       letter-spacing: 2px;
//       color: white;
//         font-family: $open-sans-regular, 'Open Sans', 'Gill Sans MT', 'Calibri', 'Trebuchet MS', sans-serif;
//     }
//   }
//   transition: all .5s;
// }

// .header-right-div-collapse-div {
//   position: fixed;
//   top: 0;
//   right: 0;
// }
