.artist-gallery {
	// max-width: 1200px;
	margin: 0 auto;
	padding: 2rem 0;
	color: #fff;

	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;
	// gap: 2rem;

	.artist-card {
		width: 23%;
		margin-bottom: 3rem;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		
		&__image {
		  width: 100%;
		  aspect-ratio: 1 / 1;
		  object-fit: cover;
		  margin-bottom: 1rem;
		  filter: grayscale(100%); // Apply grayscale filter to make the image black and white
		}
		
		&__name {
		  font-size: 1.3rem;
		  margin-bottom: 0.5rem;
		}
		
		&__specialty {
		  font-size: 1rem;
		  margin-bottom: 1rem;
		  color: #888;
		}
		
		&__description {
		  font-size: 0.9rem;
		  margin-bottom: 1rem;
		}
		
		&__view-gallery {
		  background-color: transparent;
		  border: none;
		  color: #4CAF50;
		  cursor: pointer;
		  font-size: 0.9rem;
		  text-transform: uppercase;

		  a {
			color: #9E62FFff;
		  }
		}
		}
		

}


.fade-in {
	opacity: 0;
	transition: opacity 1s ease-in-out;
}

.fade-in.visible {
	opacity: 1;
}
