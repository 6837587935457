@import "../../common/variables";

.panel {
  // width: 100%;
  // height: 100%;
  margin-top: 32px;
  // margin-bottom: 32px;
  // background-color: #c5d6d01c; // white;
  border-radius: 2px;
  z-index: 0;



  .data-art-title {

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;

    .left-align {
      margin-right: 16px; // Add margin to create space between title and read-more
    }

    .read-more {
      // align-self: flex-end;
      a {
        font-size: 12px;
        font-family: $open-sans-regular, $alibaba-puhuiti-regular, 'Gill Sans MT', 'Calibri', 'Trebuchet MS', sans-serif;
        letter-spacing: 3px;
        color: $black-base;
        background-color: $blue-light;
        padding: 4px 4px 4px 8px;
        position: relative;
        overflow: hidden;
        display: inline-block;
        transition: all 0.5s ease;

        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: -100%;
          width: 100%;
          height: 100%;
          background-color: #ffffff;
          transition: left 0.5s ease;
          z-index: -1;
        }

        &:hover {
          background-color: $blue-heavy;
          color: #ffffff;
          // font-weight: bold;

          &::before {
            left: 0;
          }
        }
      }
    }
  }


  .panel-header {
    // height: 45px;
    // border-bottom: 1px solid #E9E9E9;
    padding-left: 13px;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;

    .left-align {
      width: 320px;

      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: center;

      .dot {
        font-size: 24px;
      }
      .panel-title {
        font-size: 32px;
        font-weight: bold;
        font-family: $open-sans-bold, $alibaba-puhuiti-bold, 'Gill Sans MT', 'Calibri', 'Trebuchet MS', sans-serif;
        color: $black-base;
      }
      .panel-title:first-letter {
        font-size: 28px;
      }
      .line {
        color: #666666; // $black-base;
        position: relative;
        // padding-left: 0.63rem;
        // padding-right: 0.63rem;
        z-index: 1;

        font-size: 20px;
        font-family: $open-sans-regular, $alibaba-puhuiti-bold, 'Gill Sans MT', 'Calibri', 'Trebuchet MS', sans-serif;
        letter-spacing: 2px;
      }
      .line:before {
        content: "";
        color: $red-middle;
        background: $red-middle;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 0.7rem; // 0.7rem;
        z-index: -1;
      }
      .line:first-letter {
        font-size: 28px;
      }
    }


    // .line {
    //   position: relative;
    //   top: 0;
    //   left: 0;
    //   // padding-left: 0.63rem;
    //   // padding-right: 0.63rem;
    //   z-index: 1;
    //   background-color: bisque;
    //   width: 100px;
    //   height: 8px;
    // }
    .right-align {
      width: 360px;

      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: space-between;

      .slider-div {
        width: 200px;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: flex-start;

        .slider-label {
          margin-right: 8px;
        }
      }
    }
    .right-align-mainview {
      width: 1110px;

      .slider-group {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: center;

        .slider-unit {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          align-items: center;
          margin: 0 5px;

          .slider-name {
            // width: 190px;
            font-size: 12px;
            display: flex;
            flex-wrap: nowrap;
            justify-content: flex-start;
          }
          .slider-body {
            width: 150px;

            .ant-select {
              width: 150px;
              padding: 0 10px;

              .ant-select-selector {
                border-radius: 5px;
                height: 26px;
                margin: 4px 0;
                font-size: $plain-text-font-size;

                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
              }
              .ant-select-arrow {
                margin-right: 10px;
              }
            }
            .ant-slider {
              .ant-slider-track {
                background-color: $button-color;
              }
              .ant-slider-handle {
                border: 2px solid $button-color;
              }
            }
            .ant-switch-checked {
              background-color: $button-color;
            }
          }
          .legend-income-quantile-unit {
            width: 30px;
            height: 20px;
            border-radius: 5px;
            border: 1.5px solid rgba(255, 255, 255, 0.486);
            margin: 0 2px;

            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: center;
          }
          .legend-pvalue-unit {
            width: 20px;
            height: 20px;
            border-radius: 5px;
            border: 1.5px solid rgba(255, 255, 255, 0.486);
            // margin: 0 2px;
          }
        }
      }

    }
  }

  .panel-body {
    font-size: $plain-text-font-size;
    // font-weight: lighter;
    font-family: $open-sans-light, $alibaba-puhuiti-light, 'Gill Sans MT', 'Calibri', 'Trebuchet MS', sans-serif;
    color: $black-base;
    letter-spacing: 1.25px;
    // line-height: 1.3;

    div {
      line-height: 1.3;
    }

    a {
      color: $green-regular;
      font-family: $open-sans-regular, $alibaba-puhuiti-regular, 'Gill Sans MT', 'Calibri', 'Trebuchet MS', sans-serif;
      // text-decoration: underline;
    }
  }
}

