// 0. font-family
@mixin font($font-family, $font-file) {
  @font-face {
    font-family: $font-family;
    src: url($font-file);
    font-weight: normal;
    font-style: normal;
  }
}
@include font('alibaba-puhuiti-bold', 'Alibaba-PuHuiTi-Bold.ttf');
@include font('alibaba-puhuiti-medium', 'Alibaba-PuHuiTi-Medium.ttf');
@include font('alibaba-puhuiti-regular', 'Alibaba-PuHuiTi-Regular.ttf');
@include font('alibaba-puhuiti-light', 'Alibaba-PuHuiTi-Light.ttf');

@include font('open-sans-extrabold', 'open-sans-extrabold.ttf');
@include font('open-sans-bold', 'open-sans-bold.ttf');
@include font('open-sans-bold-italic', 'OpenSansBoldItalic.ttf');
@include font('open-sans-regular', 'open-sans-regular.ttf');
@include font('open-sans-regular-italic', 'OpenSansItalic.ttf');
@include font('open-sans-light', 'OpenSansLight.ttf');
@include font('open-sans-light-italic', 'OpenSansLightItalic.ttf');

@include font('qianmo-handwrite', 'qianmo-handwrite.ttf');


$alibaba-puhuiti-bold: 'alibaba-puhuiti-bold';
$alibaba-puhuiti-medium: 'alibaba-puhuiti-medium';
$alibaba-puhuiti-regular: 'alibaba-puhuiti-regular';
$alibaba-puhuiti-light: 'alibaba-puhuiti-light';

$open-sans-extrabold: 'open-sans-extrabold';
$open-sans-bold: 'open-sans-bold';
$open-sans-bold-italic: 'open-sans-bold-italic';
$open-sans-regular: 'open-sans-regular';
$open-sans-regular-italic: 'open-sans-regular-italic';
$open-sans-light: 'open-sans-light';
$open-sans-light-italic: 'open-sans-light-italic';

$qianmo-handwrite: 'qianmo-handwrite';

// 0. unified styles
$header-font-size: 14px;
$title-font-size: 16px;
$subtitle-font-size: 14px;
$plain-text-font-size: clamp(0.85rem, calc(0.85rem + ((1vw - 7.24px) * 0.2)), 0.95rem); // Scales between 0.85rem and 0.95rem, with 724px breakpoint

$subtitle-height: 22px;
$subtitle-margin: 20px;
$subtitle-antchor-width: 5px;

$box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.1);

// color
// kellogg theme
$green-light: #E4E0EE;
$green-regular: #4A4A4A; // #4F2582; // kellogg purple;
// $green-dark: #815636;
// $red-light: #e9d1c0;
// $red-regular: #cfa495;
// $red-dark: #c49289;
$blue-light: #E4E0EE;
$blue-regular: #836EAA;
$blue-bold: #684c96;
// $blue-dark: #718592;
$blue-heavy: #4F2582;
$black-base: #3b3b3b; // #3b3b3b; 
$red-middle: #E4E0EE; // Purple 10
$purple-80: #624D9C;
$purple-60: #8073AE;
$purple-40: #A89FC9;


// 1. summer-theme styles
// $green-light: #c5d6d0;
// $green-regular: #609e90;
// $green-dark: #426f65;
// $red-light: #e9d1c0;
// $red-regular: #cfa495;
// $red-dark: #c49289;
// $blue-light: #c6d0d9;
// $blue-regular: #a2b6c3;
// $blue-bold: #65859a;
// $blue-dark: #718592;
// $blue-heavy: #0f293a;
// $black-base: #3b3b3b; // #0e1e29;
// $red-middle: #fed9ca;

// // 2. fall-theme styles
// // $green-light: #E8CAC4;
// // $green-regular: #bd6354;
// // $green-dark: #844133;
// // $red-light: #e9d1c0;
// // $red-regular: #cfa495;
// // $red-dark: #c49289;
// // $blue-light: #c6d0d9;
// // $blue-regular: #a2b6c3;
// // $blue-bold: #65859a;
// // $blue-dark: #718592;
// // $blue-heavy: #0f293a;
// // $black-base: #3b3b3b; // #0e1e29;
// // $red-middle: #e4c3a9;

// $green-light: #E7D3C5;
// $green-regular: #ba7f57;
// $green-dark: #815636;
// $red-light: #e9d1c0;
// $red-regular: #cfa495;
// $red-dark: #c49289;
// $blue-light: #c6d0d9;
// $blue-regular: #a2b6c3;
// $blue-bold: #65859a;
// $blue-dark: #718592;
// $blue-heavy: #0f293a;
// $black-base: #3b3b3b; // #0e1e29;
// $red-middle: #c6d0d9; // #C1CED7;

// // winter-theme styles
// $green-light: #468faf;
// $green-regular: #3a7ca5; // #3a7ca5;
// // $green-dark: #815636;
// // $red-light: #e9d1c0;
// // $red-regular: #cfa495;
// // $red-dark: #c49289;
// $blue-light: #c6d0d9;
// $blue-regular: #a2b6c3;
// $blue-bold: #65859a;
// // $blue-dark: #718592;
// $blue-heavy: #0f293a;
// $black-base: #3b3b3b; 
// $red-middle: #d9dcd6; 

// 1. view sizes
$header-data-div-width: 420px; // 350px;
$system-width: 1920px;
$system-height: 1080px;
$system-sider: 450px;
$system-content: calc(1920px - $system-sider);

$descriptive-statistics-view-width: 450px;
$descriptive-statistics-view-height: 1010px;
$descriptive-statistics-view-body-height: calc($descriptive-statistics-view-height - 45px);
$descriptive-statistics-uoa-individual-height: 475px;
$descriptive-statistics-uoa-individual-body-height: calc($descriptive-statistics-uoa-individual-height - $subtitle-height - $subtitle-margin*2);
$descriptive-statistics-uoa-individual-flow-div-width: 180px;
$descriptive-statistics-uoa-individual-flow-div-height: 120px;
$descriptive-statistics-uoa-individual-flow-div-header-height: 23px;
$descriptive-statistics-uoa-individual-flow-div-body-height: calc($descriptive-statistics-uoa-individual-flow-div-height - $descriptive-statistics-uoa-individual-flow-div-header-height);

$descriptive-statistics-uoa-person-year-height: 440px;
$descriptive-statistics-uoa-person-year-inside-div-height: 380px;

$main-view-width: 1440px;
$main-view-height: 690px;
$main-view-subtitle-group-height: 40px;
$main-view-main-body-height: 600px;
$main-view-iv-list-div-width: 353px;
$main-view-linked-line-width: 50px;
$main-view-impact-div-width: 1000px;
$main-view-param-folder-div-width: 27px;
$main-view-param-folder-title-width: 164px;
$main-view-param-folder-div-unfold-width: 320px;
$main-view-param-folder-div-unfold-height: 900px; // 640px;
$mainview-ivlist-row-height: 32px;
$mainview-ivlist-row-margintop: 10px;
$mainview-ivlist-row-eventnameunit-width: 143px;
$mainview-ivlist-row-horizonchartunit-width: 200px;
$mainview-ivlist-row-horizonchartunit-height: 32px;
$mainview-ivlist-row-horizonchartunit-bar-width: 20px;

// $mainview-impacttimeline-background-width: 990px;
$mainview-impacttimeline-stage-width: 166px;
$mainview-impacttimeline-stage-horizontal-margin: 12px;

$mainview-stage-title-group-height: 54px;
$mainview-careerflow-group-height: 315px;
$mainview-careerflow-group-margintop: 19px;
$mainview-glyph-linechart-height: 210px;
$mainview-impacttimeline-height: calc($mainview-stage-title-group-height + $mainview-careerflow-group-height + $mainview-glyph-linechart-height + 20px);
$mainview-glyph-linechart-unit-height: 100px;
$main-view-clusterlabel-div-width: 28px;
$main-view-clusterlabel-div-height: 92px;
$main-view-clusterlabel-div-title-height: 20px;
$main-view-clusterlabel-div-body-height: calc($main-view-clusterlabel-div-height - $main-view-clusterlabel-div-title-height);

$prediction-view-width: 1440px;
$prediction-view-height: 310px;
$prediction-view-sequencelist-div-width: 380px;
$prediction-view-sankeydiagram-div-width: 1035px;
$prediction-view-subdiv-height: 245px;

// 1.1 margins
$descriptive-statistics-view-left-right-margin: 13px;

// 2. colors
$base-color: #98ACC0;
$button-color: #7E99B1; // #7E8FA6;
$table-color: #434343;
$highlight-color: #E9CA9B;

$event-button-border-color: #CED5DE;
$event-button-background-color: #F3F5F7;

$marriage-event-color: #ADD8F7;
$childbearing-event-color: #FCCCA7;
$migration-event-color: #C3B9FA;
$education-event-color: #A7E1C4;
$first-job-event-color: #FFE9A7;

$div-border-grey: #D9D9D9;

$glyph-coef-stroke-negative: #C69480;
$glyph-coef-stroke-positive: #93A49E;
$glyph-ecoef-stroke: #93A49E;
$glyph-donut-background: #DFDFDF;
$glyph-donut-stroke: #B9C3D0;